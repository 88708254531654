import { setCookie } from '../../scripts/utils/cookies'
import { getCssVar, setCssVar } from '../../scripts/utils/css'
import { getHostPrimaryDomain } from '../../scripts/utils/location'

export const selector = '.cookie-banner'

export default (
    el = null
) => {
    if (!el)
        return

    console.groupCollapsed('CookieBanner')

    const cookieBtnAccept = el.querySelector('.cookie-banner-accept')

    const cookieBtnDeny = el.querySelector('.cookie-banner-deny')

    if (!cookieBtnAccept
            && !cookieBtnDeny)
        return

    const setCookieBannerCssVar = () => {
        const content = el
            && el.querySelector('.cookie-banner-content')

        const bannerHeight = `${(content || {}).clientHeight || 0}px`

        if (bannerHeight !== getCssVar('--cookie-banner-height'))
            setCssVar('--cookie-banner-height', bannerHeight)
    }

    setCookieBannerCssVar()

    el.id = el.id
        || `cookie-banner-${(new Date()).getTime()}`

    const setAllowCookieBanner = (e) => {
        const value = e.target === cookieBtnAccept
            ? 'granted'
            : 'denied'

        setCookie(el.id, value, {
            expires: 365,
            domain: `.${getHostPrimaryDomain()}`,
            sameSite: 'none'
        })

        if (window.gtag)
            window.gtag('consent', 'update', {
                analytics_storage: value
            })

        el.parentElement
            .removeChild(el)

        setCookieBannerCssVar()
    }

    cookieBtnAccept
        .addEventListener('click', setAllowCookieBanner)

    cookieBtnDeny
        .addEventListener('click', setAllowCookieBanner)

    window.addEventListener('resize', setCookieBannerCssVar)

    if (import.meta.hot)
        import.meta.hot
            .on('vite:beforeUpdate', () => {
                if (cookieBtnAccept)
                    cookieBtnAccept
                        .removeEventListener('click',
                            setAllowCookieBanner)

                if (cookieBtnDeny)
                    cookieBtnDeny
                        .removeEventListener('click',
                            setAllowCookieBanner)

                window.removeEventListener('resize', setCookieBannerCssVar)
            })

    // Log els
    console.log(el)

    console.groupEnd()

    return el
}
