import {
    createEvent,
    isTouchDevice
} from '../../scripts/utils/dom'

// Save a list of named combobox actions, for future readability
const SelectActions = {
    Close: 0,
    CloseSelect: 1,
    First: 2,
    Last: 3,
    Next: 4,
    Open: 5,
    PageDown: 6,
    PageUp: 7,
    Previous: 8,
    Select: 9,
    Type: 10
}

export const selector = '.select'

export default (
    el = null
) => {
    const select = el
        && el.querySelector('select')

    const isTouch = isTouchDevice()

    if (el
            && !el.classList.contains('touch')
            && !el.classList.contains('no-touch'))
        el.classList
            .add(`${isTouch
                ? ''
                : 'no-'}touch`)

    if (!select
            || select.multiple
            || isTouch)
        return

    console.groupCollapsed('Select')

    // Constants
    const { id } = select

    let searchString = ''

    let searchTimeout

    // Interactive Els
    const label = id
        && el.querySelector('label.select-label')

    let box = el.querySelector('.select-box')

    let combobox = box
        && box.querySelector('[role=combobox]')

    let listbox = box
        && box.querySelector('[role=listbox]')

    // Utils
    const getOptions = () => Array.from(select.children)

    const getOptionsEnabled = () =>
        Array.from(select.querySelectorAll('option'))
            .filter(_el => !_el.disabled)

    const isComboboxExpanded = () => combobox
        && combobox.getAttribute('aria-expanded') === 'true'

    const getComboboxActiveIndex = () => combobox
        && parseFloat(combobox.getAttribute('data-active-index') || '0')

    const setComboboxActiveIndex = (index = getComboboxActiveIndex()) => {
        if (!combobox
                || !listbox)
            return

        index = index || 0

        combobox.setAttribute('data-active-index', index)

        const isExpanded = isComboboxExpanded()

        if (!isExpanded)
            combobox.removeAttribute('aria-activedescendant')

        const active = isExpanded
            && getOptionsEnabled()[index]

        const current = listbox.querySelector('[data-active-descendant=true]')

        if (current
                && current !== active)
            current.removeAttribute('data-active-descendant')

        if (active
                && active.id)
            combobox.setAttribute('aria-activedescendant', active.id)

        const listboxOption = active
            && active.id
            && listbox.querySelector(`[data-id="${active.id}"]`)

        if (listboxOption
                && listboxOption.getAttribute('data-active-descendant')
                    !== 'true')
            listboxOption.setAttribute('data-active-descendant', true)

        if (!isExpanded
                || !listboxOption
                || !isScrollable(listbox))
            return

        maintainScrollVisibility(listboxOption, listbox)

        if (!isElementInView(listboxOption))
            listboxOption.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest'
            })
    }

    const setSelectedListboxOption = (selectOption) => {
        if (selectOption) {
            select.selectedIndex = getOptions()
                .map((_el, idx) => _el.id === selectOption.id
                    ? idx
                    : null)
                .filter(idx => idx || idx === 0)[0] || 0

            select.value = selectOption.value

            selectOption.selected = true

            select.dispatchEvent(createEvent('change'))
        }

        const selected = select.querySelector('option:checked')

        const option = selected.id
            && listbox.querySelector(`[data-id="${selected.id}"]`)

        const listboxOptions = Array
            .from(listbox.querySelectorAll('[role=option]'))

        listboxOptions
            .forEach(_el => _el === option
                ? _el.setAttribute('aria-selected', true)
                : _el.removeAttribute('aria-selected'))

        if (!option)
            return

        const isPlaceholder = getOptions().indexOf(selected) < 1
            && !selected.value
            && selected.disabled

        const placeholderClassname = isPlaceholder
            ? ' select-box-input-placeholder'
            : ''

        combobox.innerHTML = `<span
            class="select-box-input-text${placeholderClassname}"
        >${option.innerText}</span>`
    }

    const renderOption = (_el, idx) => {
        if (!_el
                || _el.tagName !== 'OPTION')
            return

        _el.id = _el.id || `${id}-option-${_el.value || idx}`

        if (!listbox
                || listbox.querySelector(`[data-id="${_el.id}"]`))
            return

        const option = document.createElement('li')

        option.setAttribute('role', 'option')

        option.setAttribute('data-id', _el.id)

        option.setAttribute('data-value', _el.value)

        if (_el.disabled)
            option.setAttribute('aria-disabled', true)

        option.id = `${_el.id}-listbox`

        option.className = 'select-box-option'

        option.innerText = _el.innerText

        return _el.parentElement === select
            ? listbox.appendChild(option)
            : option
    }

    const renderOptgroup = (_el, idx) => {
        const options = Array.from(_el.children)
            .filter(option => option.tagName === 'OPTION')
            .map((option, _idx) =>
                (option.id = `${id}-optgroup-${idx}-option-${
                    option.value || _idx}`)
                && option)
            .map(renderOption)
            .map(option => (option || {}).outerHTML)
            .join('\n')

        const optgroup = document.createElement('li')

        optgroup.innerHTML = `
            <ul
                id="${id}-optgroup-${idx}"
                class="select-box-optgroup"
                role="group"
            >
                <li
                    id="${id}-optgroup-${idx}-label"
                    class="select-box-optgroup-label"
                    role="presentation"
                >${_el.getAttribute('label') || ''}</li>
                ${options}
            </ul>
        `

        listbox.appendChild(optgroup)
    }

    const toggleComboboxExpanded = (focusCombobox = false, activeIndex) => {
        if (!combobox
                || !listbox)
            return

        const isExpand = !isComboboxExpanded()

        combobox.setAttribute('aria-expanded', isExpand)

        setComboboxActiveIndex(activeIndex)

        if (isExpand && !isElementInView(combobox))
            combobox.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest'
            })

        if (focusCombobox)
            combobox.focus()
    }

    // Event fns
    const onSelectChange = (e) => console.log(e)

    const onComboboxClick = (e) => {
        const isExpand = !isComboboxExpanded()

        const selected = getOptionsEnabled()
            .filter(option => option.selected)[0]

        const index = isExpand
                && selected
            ? getOptionsEnabled().indexOf(selected)
            : getComboboxActiveIndex()

        toggleComboboxExpanded(isExpand, index)
    }

    const onComboboxKeydown = (e) => {
        const action = getActionFromKey(e, isComboboxExpanded())

        if (action === SelectActions.Next
                || action === SelectActions.Previous
                || action === SelectActions.PageUp
                || action === SelectActions.PageDown) {
            e.preventDefault()

            setComboboxActiveIndex(
                getUpdatedIndex(getComboboxActiveIndex(),
                    getOptionsEnabled().length,
                    action))
        }

        if (action === SelectActions.CloseSelect) {
            e.preventDefault()

            setSelectedListboxOption(
                getOptionsEnabled()[getComboboxActiveIndex()])

            return isComboboxExpanded()
                && toggleComboboxExpanded()
        }

        if (action === SelectActions.Type) {
            e.preventDefault()

            return onComboboxType(e.key)
        }

        if (action === SelectActions.Open
                || action === SelectActions.Close) {
            e.preventDefault()

            const selected = getOptionsEnabled()
                .filter(option => option.selected)[0]

            const index = SelectActions.Open
                    && selected
                ? getOptionsEnabled().indexOf(selected)
                : getComboboxActiveIndex()

            return toggleComboboxExpanded(SelectActions.Open, index)
        }
    }

    const onComboboxType = (key) => {
        if (!isComboboxExpanded())
            toggleComboboxExpanded()

        if (searchTimeout)
            window.clearTimeout(searchTimeout)

        searchTimeout = window.setTimeout(() => (searchString = ''), 1000)

        searchString += key

        const options = getOptionsEnabled()

        const searchIndex = getIndexByLetter(
            options
                .map(option => option.innerText || ''),
            searchString,
            getComboboxActiveIndex())

        if (searchIndex < 0) {
            searchString = ''
            return window.clearTimeout(searchTimeout)
        }

        setComboboxActiveIndex(searchIndex)
    }

    const onComboboxBlur = (e) => {
        if (listbox.contains((e || {}).relatedTarget))
            return

        if (!isComboboxExpanded())
            return

        toggleComboboxExpanded()
    }

    const onListboxClick = (e) => {
        const { target } = e

        if (target.role !== 'option'
                || !target.getAttribute('data-id')
                || target.getAttribute('aria-disabled') === 'true'
                || target.getAttribute('aria-selected') === 'true')
            return

        const selectOption = select
            .querySelector(`#${target.getAttribute('data-id')}`)

        if (!selectOption)
            return

        setSelectedListboxOption(selectOption)

        toggleComboboxExpanded()
    }

    // Init
    if (!isTouch
            && !box) {
        box = document.createElement('div')

        box.className = 'select-box'

        if (label)
            label.removeAttribute('for')

        if (label
                && !label.id)
            label.id = `${id}-select-label`

        const labelId = (label || {}).id

        const labelledBy = labelId
            ? `aria-labelledby="${labelId}"`
            : ''

        box.innerHTML = `
            <div
                id="${id}-combobox"
                class="select-box-input"
                role="combobox"
                tabindex="0"
                data-active-index="${select.selectedIndex || 0}"
                aria-controls="${id}-listbox"
                aria-expanded="false"
                aria-haspopup="listbox"
                aria-required="${select.required}"
                ${labelledBy}
            ></div>
            <ul
                id="${id}-listbox"
                class="select-box-menu"
                role="listbox"
                tabindex="-1"
                ${labelledBy}
            ></ul>
        `

        combobox = box.querySelector(`#${id}-combobox`)

        listbox = box.querySelector(`#${id}-listbox`)

        select.insertAdjacentElement('afterend', box)

        getOptions()
            .forEach((_el, idx) =>
                _el.tagName === 'OPTGROUP'
                    ? renderOptgroup(_el, idx)
                    : renderOption(_el, idx))

        setSelectedListboxOption()
    }

    if (!el.getAttribute('data-ready'))
        el.setAttribute('data-ready', true)

    select.addEventListener('change', onSelectChange)

    if (combobox) {
        combobox
            .addEventListener('click', onComboboxClick)

        combobox
            .addEventListener('keydown', onComboboxKeydown)

        combobox
            .addEventListener('blur', onComboboxBlur)
    }

    if (listbox) {
        listbox
            .addEventListener('click', onListboxClick)

        listbox
            .addEventListener('focusout', onComboboxBlur)
    }

    if (import.meta.hot)
        import.meta.hot
            .on('vite:beforeUpdate', () => {
                select.removeEventListener('change', onSelectChange)

                if (combobox) {
                    combobox
                        .removeEventListener('click', onComboboxClick)

                    combobox
                        .removeEventListener('keydown', onComboboxKeydown)

                    combobox
                        .removeEventListener('blur', onComboboxBlur)
                }

                if (listbox) {
                    listbox
                        .removeEventListener('click', onListboxClick)

                    listbox
                        .removeEventListener('focusout', onComboboxBlur)
                }
            })

    console.log(el, createEvent)

    console.groupEnd()

    return el
}

/*
* Helper functions
*/

// filter an array of options against an input string
// returns an array of options that begin with the filter string, case-independent
function filterOptions (options = [], filter, exclude = []) {
    console.log(options)
    return options.filter((option) => {
        const matches = option.toLowerCase().indexOf(filter.toLowerCase()) === 0
        return matches && exclude.indexOf(option) < 0
    })
}

// map a key press to an action
function getActionFromKey (event, menuOpen) {
    const { key, altKey, ctrlKey, metaKey } = event

    const openKeys = ['ArrowDown', 'ArrowUp', 'Enter', ' '] // all keys that will do the default open action

    // handle opening when closed
    if (!menuOpen && openKeys.includes(key))
        return SelectActions.Open

    // home and end move the selected option when open or closed
    if (key === 'Home')
        return SelectActions.First

    if (key === 'End')
        return SelectActions.Last

    // handle typing characters when open or closed
    const typingCharacters = key === 'Backspace'
        || key === 'Clear'
        || (key.length === 1
            && key !== ' '
            && !altKey
            && !ctrlKey
            && !metaKey)

    if (typingCharacters)
        return SelectActions.Type

    // handle keys when open
    if (menuOpen)
        if (key === 'ArrowUp' && altKey)
            return SelectActions.CloseSelect
        else if (key === 'ArrowDown' && !altKey)
            return SelectActions.Next
        else if (key === 'ArrowUp')
            return SelectActions.Previous
        else if (key === 'PageUp')
            return SelectActions.PageUp
        else if (key === 'PageDown')
            return SelectActions.PageDown
        else if (key === 'Escape')
            return SelectActions.Close
        else if (key === 'Enter' || key === ' ')
            return SelectActions.CloseSelect
}

// return the index of an option from an array of options, based on a search string
// if the filter is multiple iterations of the same letter (e.g "aaa"), then cycle through first-letter matches
function getIndexByLetter (options, filter, startIndex = 0) {
    const orderedOptions = [
        ...options.slice(startIndex),
        ...options.slice(0, startIndex)
    ]

    const firstMatch = filterOptions(orderedOptions, filter)[0]
    const allSameLetter = (array) => array.every((letter) => letter === array[0])

    // first check if there is an exact match for the typed string
    if (firstMatch)
        return options.indexOf(firstMatch)

    // if the same letter is being repeated, cycle through first-letter matches
    else if (allSameLetter(filter.split(''))) {
        const matches = filterOptions(orderedOptions, filter[0])
        return options.indexOf(matches[0])
    }

    // if no matches, return -1
    else
        return -1
}

// get an updated option index after performing an action
function getUpdatedIndex (currentIndex, maxIndex, action) {
    const pageSize = 10 // used for pageup/pagedown

    switch (action) {
    case SelectActions.First:
        return 0
    case SelectActions.Last:
        return maxIndex
    case SelectActions.Previous:
        return Math.max(0, currentIndex - 1)
    case SelectActions.Next:
        return Math.min(maxIndex, currentIndex + 1)
    case SelectActions.PageUp:
        return Math.max(0, currentIndex - pageSize)
    case SelectActions.PageDown:
        return Math.min(maxIndex, currentIndex + pageSize)
    default:
        return currentIndex
    }
}

// check if element is visible in browser view port
function isElementInView (element) {
    const bounding = element.getBoundingClientRect()

    return ((bounding.top >= 0)
        && (bounding.left >= 0)
        && (bounding.bottom
            <= (window.innerHeight
                || document.documentElement.clientHeight))
        && (bounding.right
            <= (window.innerWidth
                || document.documentElement.clientWidth))
    )
}

// check if an element is currently scrollable
function isScrollable (element) {
    return element && element.clientHeight < element.scrollHeight
}

// ensure a given child element is within the parent's visible scroll area
// if the child is not visible, scroll the parent
function maintainScrollVisibility (activeElement, scrollParent) {
    const { offsetHeight, offsetTop } = activeElement
    const { offsetHeight: parentOffsetHeight, scrollTop } = scrollParent

    const isAbove = offsetTop < scrollTop
    const isBelow = offsetTop + offsetHeight > scrollTop + parentOffsetHeight

    if (isAbove)
        scrollParent.scrollTo(0, offsetTop)
    else if (isBelow)
        scrollParent.scrollTo(0, offsetTop - parentOffsetHeight + offsetHeight)
}
