import { getCssVar, setCssVar } from '../../scripts/utils/css'
import { getCookie, setCookie } from '../../scripts/utils/cookies'
import { getHostPrimaryDomain } from '../../scripts/utils/location'
import { isVisible } from 'bootstrap/js/src/util'

export const selector = '.global-header'

export default (
    el = null
) => {
    const { Dropdown } = window.bootstrap

    if (!el)
        return

    console.groupCollapsed('GlobalHeader')

    // Data attributes
    const dataAttrDropdownExpanded = 'data-designtex-global-header-dropdown-expanded'

    const dataAttrNavbarFixedTop = 'data-designtex-global-header-navbar-fixed-top'

    const dataAttrNavbarFixedTopHeight = 'data-designtex-global-header-navbar-fixed-top-height'

    const dataAttrNavbarFixedTopOffset = 'data-designtex-global-header-navbar-fixed-top-offset'

    const dataAttrNavbarFixedTopDisabled = 'data-designtex-global-header-navbar-fixed-top-disabled'

    const dataAttrNavbarFixedTopRevealScrollUp = 'data-designtex-global-header-navbar-fixed-top-reveal-scroll-up'

    const dataAttrNavbarFixedTopUnstick = 'data-designtex-global-header-navbar-fixed-top-unstick'

    const dataAttrNavbarFixedTopHeightDiff = 'data-designtex-global-header-navbar-fixed-top-height-diff'

    const dataAttrBoundsInViewport = 'data-designtex-global-header-bounds-in-viewport'

    // Css Vars
    const cssVarNavbarFixedTopHeight = dataAttrNavbarFixedTopHeight
        .replace(/^data/, '-')

    const cssVarNavbarFixedTopOffset = dataAttrNavbarFixedTopOffset
        .replace(/^data/, '-')

    const cssVarHeight = '--designtex-global-header-height'

    // Constants
    const isBigCommerce = window.location.hostname
        === el.getAttribute('data-designtex-bigcommerce-hostname')

    // Interactive Elements
    const dropdownToggles = Array.from(el
        .querySelectorAll('.dropdown-toggle'))

    const menuPrimaryLists
        = Array.from(el.querySelectorAll('.global-header-menu-primary-list'))

    const navbarFixedTop = el
        .querySelector('.global-header-navbar.fixed-top')

    const announcementBanner = el
        .querySelector('.global-header-announcement-banner')

    const announcementBannerDimissBtn = announcementBanner
        && announcementBanner.querySelector('.btn-dismiss')

    const cartIframe = el
        .querySelector('#global-header-cart-frame')

    const cartOffcanvas = !el
        .hasAttribute('data-designtex-global-header-hash')
            && el.querySelector('#global-header-cart-offcanvas')

    const cartList = cartOffcanvas
        && cartOffcanvas.querySelector('ul.cart-list')

    const cartQuantities = Array.from(el
        .querySelectorAll('.cart-quantity'))

    // Util fns
    const isScrolldirUp = () => document.documentElement
        .getAttribute('data-scrolldir')
            === 'up'

    const isNavbarFixedTopRevealScrollUp = () =>
        el.getAttribute(dataAttrNavbarFixedTopRevealScrollUp)
            === 'true'

    const isNavbarFixedTopDisplayNone = () => navbarFixedTop
        && window.getComputedStyle(navbarFixedTop)
            .display === 'none'

    const getNavbarFixedTopOffset = () => {
        if (isNavbarFixedTopDisplayNone())
            return 0

        const container = Array.from(el.children)
            .filter(_el => _el.classList.contains('container'))[0]

        const paddingBottom = !isNavbarFixedTopRevealScrollUp()
            ? window.getComputedStyle(container || el).paddingBottom
            : '0'

        return parseFloat(paddingBottom)
            + parseFloat(getCssVar('--wp-admin--admin-bar--height') || '0')
    }

    const isBottomInViewport = () =>
        el.getBoundingClientRect().bottom
            > getNavbarFixedTopOffset()

    const isDropdownExpanded = () =>
        el.getAttribute(dataAttrDropdownExpanded)
            === 'true'

    const isNavbarFixedTop = () =>
        el.getAttribute(dataAttrNavbarFixedTop)
            === 'true'

    const isNavbarFixedTopDisabled = () =>
        el.getAttribute(dataAttrNavbarFixedTopDisabled)
            === 'true'

    const isNavbarFixedTopUnstick = () =>
        el.getAttribute(dataAttrNavbarFixedTopUnstick)
            === 'true'

    const isNavbarFixedTopHeightDiff = () => {
        const navHeightDiff = parseFloat(
            el.getAttribute(dataAttrNavbarFixedTopHeightDiff))

        return !isNaN(navHeightDiff)
            && navHeightDiff > 1
    }

    const getHeaderNavsBoundsTop = () => {
        const headerNavs = Array.from(el.children)
            .filter(_el => _el.role !== 'alert'
                && window.getComputedStyle(_el)
                    .position === 'relative')

        const { top } = (headerNavs[0]
            && headerNavs[0].getBoundingClientRect())
                || { top: 0 }

        return Math.round(top)
    }

    const setNavbarFixedTopHeightAttrs = () => {
        if (!navbarFixedTop)
            return

        const height = navbarFixedTop.clientHeight || 0

        if (el.getAttribute(dataAttrNavbarFixedTopHeight)
                !== `${height}`)
            el.setAttribute(dataAttrNavbarFixedTopHeight,
                height)

        if (getCssVar(cssVarNavbarFixedTopHeight) !== `${height}px`)
            setCssVar(cssVarNavbarFixedTopHeight, `${height}px`)
    }

    const setNavbarFixedTopDisabledAttr = () => {
        if (!navbarFixedTop)
            return

        const isDisableNavbarFixedTop
            = (navbarFixedTop.clientHeight || 0) >= window.innerHeight * 0.45

        if (isDisableNavbarFixedTop
                && !el.getAttribute(dataAttrNavbarFixedTopDisabled))
            el.setAttribute(dataAttrNavbarFixedTopDisabled, true)

        if (!isDisableNavbarFixedTop
                && el.getAttribute(dataAttrNavbarFixedTopDisabled)
                    === 'true')
            el.removeAttribute(dataAttrNavbarFixedTopDisabled)
    }

    const setNavbarFixedTopOffsetAttrs = () => {
        if (!navbarFixedTop)
            return

        const offset = getNavbarFixedTopOffset() || 0

        if (el.getAttribute(dataAttrNavbarFixedTopOffset)
                !== `${offset}`)
            el.setAttribute(dataAttrNavbarFixedTopOffset,
                offset)

        if (getCssVar(cssVarNavbarFixedTopOffset) !== `${offset}px`)
            setCssVar(cssVarNavbarFixedTopOffset, `${offset}px`)
    }

    const setNavbarFixedTopAttr = () => {
        if (isBottomInViewport()
                && !el.getAttribute(dataAttrBoundsInViewport))
            el.setAttribute(dataAttrBoundsInViewport, true)

        if (!isBottomInViewport()
                && el.getAttribute(dataAttrBoundsInViewport))
            el.removeAttribute(dataAttrBoundsInViewport)

        if (!isNavbarFixedTopHeightDiff()
                && isNavbarFixedTop()
                && isScrolldirUp()) {
            const top = getHeaderNavsBoundsTop()

            if (top < 0)
                return

            el.setAttribute(dataAttrNavbarFixedTopUnstick, true)

            el.removeAttribute(dataAttrNavbarFixedTop)
        }

        if (!isScrolldirUp()
                && isNavbarFixedTopUnstick())
            el.removeAttribute(dataAttrNavbarFixedTopUnstick)

        if (!isNavbarFixedTop()
                && !isBottomInViewport()
                && !isNavbarFixedTopDisabled()
                && !isNavbarFixedTopDisplayNone()
                && (isScrolldirUp()
                    || !isNavbarFixedTopRevealScrollUp())
                && !el.getAttribute(dataAttrDropdownExpanded))
            return el.setAttribute(dataAttrNavbarFixedTop, true)

        if (isNavbarFixedTop()
                && (isBottomInViewport()
                    || isNavbarFixedTopDisplayNone()
                    || isNavbarFixedTopDisabled()
                    || (isNavbarFixedTopRevealScrollUp()
                        && !isScrolldirUp())))
            isBottomInViewport()
                ? el.removeAttribute(dataAttrNavbarFixedTop)
                : el.setAttribute(dataAttrNavbarFixedTop, false)
    }

    const setNavbarFixedTopHeightDiffAttr = (e) => {
        const diff = parseFloat(
            el.getAttribute(dataAttrNavbarFixedTopHeightDiff))

        if ((e || {}).type === 'scroll'
                && !isNaN(diff))
            return

        const headerNavs = Array.from(el.children)
            .filter(_el => _el.role !== 'alert'
                && window.getComputedStyle(_el)
                    .position === 'relative')

        const headerNavsHeight = headerNavs
            .map(_el => _el.clientHeight || 0)
            .reduce((acc, curr) => acc + curr, 0)

        let navHeightDiff = navbarFixedTop.clientHeight - headerNavsHeight

        if (navHeightDiff < 0)
            navHeightDiff = navHeightDiff * -1

        if (navHeightDiff !== diff)
            el.setAttribute(dataAttrNavbarFixedTopHeightDiff, navHeightDiff)
    }

    const setHeightCssVar = (e) => {
        const height = Array.from(el.children)
            .filter(_el => window.getComputedStyle(_el)
                .position === 'relative')
            .map(_el => _el.clientHeight || 0)
            .reduce((acc, curr) => acc + curr, 0)

        if (getCssVar(cssVarHeight) !== `${height}px`)
            setCssVar(cssVarHeight, `${height}px`)
    }

    const setMenuPrimaryScrollState = (_el) => {
        if (menuPrimaryLists.indexOf(_el) < 0)
            return

        const isScrollable = _el.scrollWidth > _el.clientWidth

        if (!isScrollable)
            return _el.parentElement.getAttribute('data-scroll-state')
                ? _el.parentElement.removeAttribute('data-scroll-state')
                : _el

        const isScrollStart = isScrollable
            && _el.scrollLeft === 0

        const isScrollEnd = isScrollable
            && Math.round(_el.scrollLeft)
                === Math.round(_el.scrollWidth - _el.clientWidth)

        if (!isScrollStart
                && !isScrollEnd
                && _el.parentElement
                    .getAttribute('data-scroll-state') !== 'scrolled')
            _el.parentElement.setAttribute('data-scroll-state', 'scrolled')

        if (isScrollStart
                && _el.parentElement
                    .getAttribute('data-scroll-state') !== 'start')
            _el.parentElement.setAttribute('data-scroll-state', 'start')

        if (isScrollEnd
                && _el.parentElement
                    .getAttribute('data-scroll-state') !== 'end')
            _el.parentElement.setAttribute('data-scroll-state', 'end')

        return _el
    }

    const onViewportChange = (e) => {
        if ((e || {}).type === 'resize')
            menuPrimaryLists.forEach(_el => setMenuPrimaryScrollState(_el))

        setHeightCssVar()
        setNavbarFixedTopHeightDiffAttr(e)
        setNavbarFixedTopDisabledAttr()
        setNavbarFixedTopAttr()
        setNavbarFixedTopHeightAttrs()
        setNavbarFixedTopOffsetAttrs()
    }

    const onMenuPrimaryListsScroll = (e) => {
        setMenuPrimaryScrollState(e.target)

        if (isNavbarFixedTopHeightDiff())
            return

        const menuPrimaryList = el
            .querySelector('.global-header-menu-primary-list')

        const navbarFixedTopMenuPrimaryList = navbarFixedTop
            .querySelector('.global-header-menu-primary-list')

        const target = isNavbarFixedTop()
            ? navbarFixedTopMenuPrimaryList
            : menuPrimaryList

        if (target !== e.target)
            return

        if (isNavbarFixedTopUnstick())
            el.removeAttribute(dataAttrNavbarFixedTopUnstick)

        const syncTarget = target === navbarFixedTopMenuPrimaryList
            ? menuPrimaryList
            : navbarFixedTopMenuPrimaryList

        syncTarget.scrollLeft = target.scrollLeft

        setMenuPrimaryScrollState(syncTarget)
    }

    const onAnnouncementBannerDismiss = (e) => {
        if (!(announcementBanner || {}).id)
            return

        setCookie(announcementBanner.id, 'dismissed', {
            expires: 365,
            domain: `.${getHostPrimaryDomain()}`,
            sameSite: 'none'
        })

        announcementBanner.parentElement
            .removeChild(announcementBanner)

        onViewportChange()
    }

    const getCartQuantity = () => parseFloat(
        (cartQuantities[0] || {}).innerText || '0')

    const setCartQuantity = (int) => cartQuantities[0]
        && (cartQuantities[0].innerHTML = (
            !cartQuantities[0].classList.contains('badge')
                ? int || '0'
                : int))

    const setCartCookies = async (e) => {
        const isInit = (e || {}).type === 'load'

        const cookieOpts = {
            expires: 30,
            sameSite: 'none',
            domain: `.${getHostPrimaryDomain()}`
        }

        const cookieCartId = getCookie('BIGCOMMERCE_CART_ID')

        const cookieCartQuantity = parseFloat(
            getCookie('BIGCOMMERCE_CART_QUANTITY') || 0)

        const domCartQuantity = getCartQuantity()

        const hasCartQuantityAttr = el
            .hasAttribute('data-designtex-bigcommerce-cart-quantity')

        if (hasCartQuantityAttr
                && el.getAttribute('data-designtex-bigcommerce-cart-quantity')
                    !== `${domCartQuantity}`)
            el.setAttribute(
                'data-designtex-bigcommerce-cart-quantity',
                domCartQuantity)

        const getCartQuantityText = (_el) => _el.classList.contains('badge')
            ? domCartQuantity || ''
            : domCartQuantity

        if (hasCartQuantityAttr)
            cartQuantities
                .filter(_el => _el.innerHTML !== `${getCartQuantityText(_el)}`)
                .forEach(_el => (_el.innerHTML = getCartQuantityText(_el)))

        if (!isBigCommerce
                && !isInit
                && (cookieCartQuantity
                        !== domCartQuantity
                    || (cookieCartQuantity
                        && !cookieCartId)))
            setCookie('BIGCOMMERCE_CART_QUANTITY',
                domCartQuantity || '',
                Object.assign(cookieOpts, {
                    expires: !domCartQuantity
                        ? 0
                        : cookieOpts.expires
                }))

        if (!isBigCommerce
                || !cartIframe)
            return

        const data = await window.fetch('/api/storefront/cart-summary')
            .then(res => res.json())
            .catch(err => console.warn(err)) || {}

        const cartId = data.id

        const cartQuantity = data.total_quantity

        const primaryDomain = getHostPrimaryDomain()

        const parsedIframeUrl = new URL(cartIframe.src)

        const isFirstParty = getHostPrimaryDomain(parsedIframeUrl.host)
            === primaryDomain

        if (isFirstParty)
            cookieOpts.domain = `.${primaryDomain}`

        if (cartId !== cookieCartId)
            setCookie(
                'BIGCOMMERCE_CART_ID',
                cartId,
                Object.assign(cookieOpts, {
                    expires: !cartId
                        ? 0
                        : cookieOpts.expires
                }))

        if (cartQuantity !== cookieCartQuantity)
            setCookie(
                'BIGCOMMERCE_CART_QUANTITY',
                cartQuantity,
                Object.assign(cookieOpts, {
                    expires: !cartQuantity
                        ? 0
                        : cookieOpts.expires
                }))

        if (!isFirstParty)
            cartIframe.contentWindow.postMessage({
                cartId,
                cartQuantity
            }, '*')
    }

    const getBigCommerceApiPath = () => el
        .getAttribute('data-designtex-bigcommerce-api-path')

    const onShowOffcanvasCart = async (e) => {
        if (e.target._isShown)
            return

        cartOffcanvas.setAttribute('data-cart-loading', true)

        const baseApiPath = getBigCommerceApiPath()

        const cartId = getCookie('BIGCOMMERCE_CART_ID')

        let data

        if (baseApiPath
                && cartId) {
            const req = await window
                .fetch(`/proxy/?url=${
                    baseApiPath}carts/${cartId}`)
                .then(res => res.json())
                .catch(err => console.warn(err)) || { data: null }

            data = req.data
        }

        if (!data) {
            console.groupCollapsed('Cart rendering aborted')
            console.log(baseApiPath, cartId, cartList, data)
            console.groupEnd()

            return cartOffcanvas.removeAttribute('data-cart-loading')
        }

        const items = (((data || {}).line_items || {}).physical_items || [])
            .sort((a, b) => a.sku === b.sku
                ? 0
                : (a.sku < b.sku
                    ? -1
                    : 1))

        const totalQuantity = items
            .map(item => item.quantity || 0)
            .reduce((acc, curr) => acc + curr, 0)

        if (getCartQuantity() !== totalQuantity
                && cartQuantities[0])
            setCartQuantity(totalQuantity)

        const priceFormatter = new Intl.NumberFormat(data.locale, {
            style: 'currency',
            currency: data.currency.code
        })

        const formatPrice = (price) => price
            ? priceFormatter.format(price)
            : 'Free'

        const getItemImgHtml = (item) => (item.image_url
            && (`
                <img
                    class="cart-item-image d-block w-100"
                    src="${item.image_url}"
                    alt="${item.name || ''}"
                >
            `)) || ''

        cartList.innerHTML = items
            .map(item => `
                <li
                    class="cart-item row pb-2 body-3"
                    data-cart-item-id="${item.id}"
                    data-cart-product-id="${item.product_id}"
                    data-cart-variant-id="${item.variant_id}"
                    data-cart-sku="${item.sku}"
                    data-cart-quantity="${item.quantity}"
                >
                    <hr role="none">

                    <div class="col cart-item-block cart-item-figure">
                        <a href="${item.url}" tabindex="-1">
                            ${getItemImgHtml(item)}
                        </a>
                    </div>

                    <div class="col cart-item-block cart-item-info">
                        <div class="cart-item-title">
                            <h2 class="cart-item-name body-2 font-weight-500">
                                <a
                                    class="cart-item-name__label"
                                    href="${item.url}"
                                >${item.name}</a>
                            </h2>

                            <ul class="cart-item-options text-color-gray">
                                <li class="cart-item-category">
                                    <span class="cart-item-label visually-hidden">Product Category:</span>
                                    <span class="cart-item-category-value"></span>
                                </li>
                                <li class="cart-item-color">
                                    <span class="cart-item-label visually-hidden">Color:</span>
                                    <span class="cart-item-color-value"></span>
                                </li>
                                <li class="cart-item-sku">
                                    <span class="cart-item-label visually-hidden">SKU:</span>
                                    <span class="cart-item-sku-value">${item.sku}</span>
                                </li>
                            </ul>
                        </div>

                        <div class="cart-item-sample text-color-gray${item.sale_price ? ' d-none' : ''}">
                            <span>Sample</span>
                        </div>

                        <div class="cart-item-price">
                            <span class="cart-item-label visually-hidden">Price</span>
                            <span
                                class="cart-item-value price--free font-weight-500"
                            >${formatPrice(item.sale_price)}</span>
                        </div>
                    </div>

                    <div class="col-auto cart-item-block cart-item-actions">
                        <button
                            class="cart-remove btn btn-link px-0 body-3 text-color-gray"
                            aria-label="Remove ${item.name} from cart"
                        >Remove</button>
                    </div>
                </li>
            `).join('')

        const cartListItems = Array.from(cartList.children || [])

        cartListItems
            .forEach(async (item) => Array
                .from(item.querySelectorAll('.cart-remove.btn'))
                .forEach(btn => btn
                    .addEventListener('click', async (e) => {
                        cartOffcanvas.setAttribute('data-cart-loading', true)

                        const id = item.getAttribute('data-cart-item-id')

                        const url = id
                            && `/proxy/?url=${
                                baseApiPath}carts/${
                                cartId}/items/${
                                id}&method=DELETE`

                        if (!url)
                            return cartOffcanvas
                                .removeAttribute('data-cart-loading')

                        await window.fetch(url)
                            .then(res => res.json())
                            .catch(err => console.warn(err))

                        item.parentElement
                            .removeChild(item)

                        const updateQuantity = Array.from(cartList.children || [])
                            .map(_el => parseFloat(
                                _el.getAttribute('data-cart-quantity') || '0'))
                            .reduce((acc, curr) => acc + curr, 0)

                        setCartQuantity(updateQuantity)

                        cartOffcanvas.removeAttribute('data-cart-loading')
                    })))

        cartOffcanvas.removeAttribute('data-cart-loading')

        cartListItems
            .forEach(async (item) => {
                const productId = item.getAttribute('data-cart-product-id')

                const variantId = item.getAttribute('data-cart-variant-id')

                const sku = item.getAttribute('data-cart-sku')

                // Category
                if (productId) {
                    const product = await window
                        .fetch(`/proxy/?url=${baseApiPath}catalog/products/${
                            productId}?include=custom_fields`)
                        .then(res => res.json())
                        .catch(err => console.warn(err)) || { data: {} }

                    const categoryValue = item
                        && item.querySelector('.cart-item-category-value:empty')

                    if (categoryValue)
                        categoryValue.innerHTML
                            = ((product.data || {}).custom_fields || [])
                                .filter(obj => obj.name === 'Product Category')
                                .map(obj => obj.value)[0] || ''
                }

                // Variant
                if (productId
                        && variantId
                        && sku) {
                    const variant = await window
                        .fetch(`/proxy/?url=${baseApiPath}catalog/products/${
                            productId}/variants/${
                            variantId}`)
                        .then(res => res.json())
                        .catch(err => console.warn(err)) || { data: {} }

                    const colorOpt = ((variant.data || {}).option_values || [])
                        .filter(obj => obj.option_display_name
                            === 'Color')[0] || {}

                    const colorValue = item
                        && item.querySelector('.cart-item-color-value:empty')

                    if (colorValue)
                        colorValue.innerHTML = (colorOpt.label || '')
                            .replace(new RegExp(
                                `-${(sku || '').replace(/-.+$/, '')}$`), '')
                }

                const info = item.querySelector('.cart-item-info')

                if (info)
                    info.style.minHeight = '0'
            })
    }

    const onHiddenOffcanvasCart = (e) => cartList
        && Array.from(cartList.children || [])
            .forEach(_el => _el.parentElement
                .removeChild(_el))

    // Dropdown Toggles
    dropdownToggles
        .forEach((toggle) => {
            if (!Dropdown)
                return

            const instance = Dropdown.getInstance(toggle)

            if (instance)
                instance.dispose()

            const dropdown = new Dropdown(toggle, {
                offset: [0, 0],
                popperConfig: {
                    strategy: 'fixed',
                    placement: 'bottom',
                    modifiers: [{
                        name: 'flip',
                        enabled: false
                    }]
                }
            })

            const isExpanded = () => toggle.getAttribute('aria-expanded')
                === 'true'

            const onShow = (e) => !isDropdownExpanded()
                && el.setAttribute(dataAttrDropdownExpanded, false)

            const onShown = (e) => !isDropdownExpanded()
                && el.setAttribute(dataAttrDropdownExpanded, true)

            toggle
                .addEventListener('show.bs.dropdown', onShow)

            toggle
                .addEventListener('shown.bs.dropdown', onShown)

            const onHide = (e) => isDropdownExpanded()
                && el.setAttribute(dataAttrDropdownExpanded, false)

            const onHidden = (e) =>
                el.removeAttribute(dataAttrDropdownExpanded)

            toggle
                .addEventListener('hide.bs.dropdown', onHide)

            toggle
                .addEventListener('hidden.bs.dropdown', onHidden)

            const autohide = () => isExpanded()
                && (!isVisible(toggle)
                    || !isBottomInViewport())
                && dropdown.hide()

            window.addEventListener('resize', autohide)

            window.addEventListener('scroll', autohide)

            const isSetDropdownTop = !navbarFixedTop
                || !navbarFixedTop.contains(dropdown._menu)

            const setDropdownTop = () => {
                const { bottom } = toggle.getBoundingClientRect()

                const top = `${bottom}px`

                if (getCssVar('--designtex-dropdown-top', dropdown._menu)
                        !== top)
                    setCssVar('--designtex-dropdown-top', top, dropdown._menu)
            }

            if (isSetDropdownTop) {
                setDropdownTop()

                window.addEventListener('resize', setDropdownTop)

                window.addEventListener('scroll', setDropdownTop)
            }

            const menuItem = toggle.parentElement.parentElement

            let onMenuItemMouseEnterDebounce

            const onMenuItemMouseEnter = (e) => {
                const show = () => isVisible(toggle)
                    && !isExpanded()
                    && dropdown.show()

                const from = e.fromElement || e.relatedTarget

                if (e.target.parentElement.contains(from))
                    return show()

                clearTimeout(onMenuItemMouseEnterDebounce)

                onMenuItemMouseEnterDebounce = setTimeout(() => {
                    const hovered = e.target.parentElement
                        .querySelector(':hover')

                    if (hovered === e.target)
                        show()
                }, 100)
            }

            const onMenuItemMouseLeave = (e) => isVisible(toggle)
                && isExpanded()
                && dropdown.hide()

            if (menuItem) {
                menuItem
                    .addEventListener('mouseenter', onMenuItemMouseEnter)

                menuItem
                    .addEventListener('mouseleave', onMenuItemMouseLeave)
            }

            if (import.meta.hot)
                import.meta.hot
                    .on('vite:beforeUpdate', () => {
                        toggle
                            .removeEventListener('show.bs.dropdown', onShow)

                        toggle
                            .removeEventListener('shown.bs.dropdown', onShown)

                        toggle
                            .removeEventListener('hide.bs.dropdown', onHide)

                        toggle
                            .removeEventListener('hidden.bs.dropdown', onHidden)

                        window.removeEventListener('resize', autohide)

                        window.removeEventListener('scroll', autohide)

                        if (isSetDropdownTop) {
                            window.removeEventListener('resize', setDropdownTop)

                            window.removeEventListener('scroll', setDropdownTop)
                        }

                        if (menuItem) {
                            menuItem
                                .removeEventListener('mouseenter',
                                    onMenuItemMouseEnter)

                            menuItem
                                .removeEventListener('mouseleave',
                                    onMenuItemMouseLeave)
                        }
                    })
        })

    // Update scroll-padding-top if scrolldir reveal is not active
    if (!isNavbarFixedTopRevealScrollUp())
        document.documentElement.style.scrollPaddingTop
            = `calc(var(--wp-admin--admin-bar--height) + var(${
                cssVarNavbarFixedTopHeight}))`

    if (announcementBannerDimissBtn)
        announcementBannerDimissBtn
            .addEventListener('click', onAnnouncementBannerDismiss)

    // Top-level Events
    window.addEventListener('resize', onViewportChange)

    window.addEventListener('scroll', onViewportChange)

    onViewportChange()

    menuPrimaryLists
        .forEach(_el => setMenuPrimaryScrollState(_el)
            && _el.addEventListener('scroll',
                onMenuPrimaryListsScroll))

    // Sync Cart ID from BigCommerce -> WP
    const cartQuantityObserver = 'MutationObserver' in window
        && new window.MutationObserver((mutationList, observer) =>
            mutationList
                .filter(record => ((record || {}).target || {}).classList
                    && record.target.classList.contains('cart-quantity'))
                .length
                    && setCartCookies())

    if (cartQuantityObserver)
        cartQuantityObserver.observe(el, {
            characterData: true,
            childList: true,
            subtree: true
        })

    if (isBigCommerce)
        window.addEventListener('load', setCartCookies)

    if (cartOffcanvas) {
        cartOffcanvas
            .addEventListener('show.bs.offcanvas', onShowOffcanvasCart)

        cartOffcanvas
            .addEventListener('hidden.bs.offcanvas', onHiddenOffcanvasCart)
    }

    // HMR
    if (import.meta.hot)
        import.meta.hot
            .on('vite:beforeUpdate', () => {
                if (announcementBannerDimissBtn)
                    announcementBannerDimissBtn
                        .removeEventListener('click',
                            onAnnouncementBannerDismiss)

                window.removeEventListener('resize', onViewportChange)

                window.removeEventListener('scroll', onViewportChange)

                menuPrimaryLists
                    .forEach(_el => _el.removeEventListener('scroll',
                        onMenuPrimaryListsScroll))

                if (cartQuantityObserver)
                    cartQuantityObserver.disconnect()

                if (cartOffcanvas) {
                    cartOffcanvas
                        .removeEventListener('show.bs.offcanvas', onShowOffcanvasCart)

                    cartOffcanvas
                        .removeEventListener('hidden.bs.offcanvas', onHiddenOffcanvasCart)
                }
            })

    // Log els
    console.log(el, dropdownToggles, navbarFixedTop)

    console.groupEnd()

    return el
}
