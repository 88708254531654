// Cookie Utility Functions

export const getCookie = (name) => {
    const cookies = document.cookie
        .split(';')
        .filter(str => str)
        .map(str => decodeURIComponent(str.trim())
            .split('='))
        .filter(arr => arr.length > 1)
        .reduce((obj, [key, value]) =>
            ({ ...obj, [key]: value }), {}) || {}

    return name
        ? cookies[name]
        : cookies
}

export const setCookie = (
    name,
    val,
    opts = {
        expires: null,
        domain: null,
        path: null,
        sameSite: null
    }
) => {
    if (!name)
        return

    let cookie = `${name}=${val || ''}; `

    if ((opts || {}).expires
            && typeof opts.expires === 'number') {
        const now = new Date()

        const expires = opts.expires < 1
            ? new Date(0)
            : new Date(now.getTime() + (opts.expires * 86400000))

        cookie += `expires=${expires.toUTCString()}; `
    }

    if ((opts || {}).domain)
        cookie += `domain=${opts.domain}; `

    cookie += `path=${opts.path || '/'}; `

    if ((opts || {}).sameSite)
        cookie += `SameSite=${opts.sameSite}; Secure;`

    document.cookie = cookie.trim()
}
