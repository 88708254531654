//* ---------------------------------------
//  CSS - Utility Functions

export const getCssVar = (name, el) => name
    && (window.getComputedStyle(el || document.documentElement)
        .getPropertyValue(name) || '').trim()

export const setCssVar = (name, val, el) => name
    && (el || document.documentElement).style
        .setProperty(name, val || '')
