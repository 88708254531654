export const selector = 'body'

export default (
    el = null
) => {
    if (!el)
        return

    console.groupCollapsed('TypographyChars')

    const namespace = 'typography-chars'

    const charsMap = [{
        name: 'sup',
        chars: [
            '®',
            '™'
        ]
    }].map(obj => Object.assign(obj, {
        className: `${namespace}-${obj.name}`
    }))

    const render = () => {
        charsMap
            .forEach((obj) => obj.chars
                .forEach((str) => {
                    const els = []

                    const xpath = document.evaluate(
                        `/html/body//*[not(@class="${
                            obj.className}") and text()[contains(., "${str}")]]`,
                        document,
                        null,
                        window.XPathResult.ANY_TYPE,
                        null)

                    let _el = xpath.iterateNext()

                    while (_el) {
                        if (_el
                                && !_el.tagName.match(/(script|style)/i)
                                && !_el.classList.contains(obj.className)
                                && !_el.parentElement.classList.contains(obj.className))
                            els.push(_el)

                        _el = xpath.iterateNext()
                    }

                    if (!els.length)
                        return

                    console.groupCollapsed(`TypographyChars render: ${str}`)
                    console.log(els)
                    console.groupEnd()

                    els
                        .map((_el) => {
                            if (!_el.classList.contains(namespace))
                                _el.classList.add(namespace)

                            const clone = _el.cloneNode(true)

                            Array.from(clone.childNodes)
                                .filter((node) => node.nodeType === 3
                                    && (node.wholeText || '').trim())
                                .forEach((node) => {
                                    const span = document.createElement('span')

                                    span.innerHTML = node.wholeText
                                    span.className = `${namespace}-text`

                                    node.parentNode
                                        .insertBefore(span, node)

                                    node.parentNode
                                        .removeChild(node)
                                })

                            _el.innerHTML
                                = clone.innerHTML
                                    .replace(
                                        new window.RegExp(`(${str})`, 'g'),
                                        `<span class="${
                                            obj.className}" aria-label="$1" data-char="$1"><span>$1</span></span>`)

                            return _el
                        })
                }))
    }

    render()

    const observer = new window.MutationObserver(render)

    observer
        .observe(el, {
            childList: true,
            subtree: true
        })

    if (import.meta.hot)
        import.meta.hot
            .on('vite:beforeUpdate', () => observer
                && observer.disconnect())

    console.groupEnd()

    return el
}
