export const selector = [
    '.offcanvas',
    '.offcanvas-sm',
    '.offcanvas-md',
    '.offcanvas-lg',
    '.offcanvas-xl',
    '.offcanvas-xxl'
].join(',')

export default (
    el = null
) => {
    const { Offcanvas } = window.bootstrap

    if (!el)
        return

    console.groupCollapsed('Offcanvas')

    let instance = Offcanvas.getInstance(el)

    if (instance)
        instance.dispose()

    const offcanvasHtmlAttr = 'data-bs-offcanvas'

    const offcanvas = new Offcanvas(el)

    const setOffcanvasHtmlAttr = (instance = offcanvas) => {
        const isShown = instance._isShown

        const isOffcanvasActive = document.documentElement
            .getAttribute(offcanvasHtmlAttr)
                === el.id

        if (!isOffcanvasActive && isShown)
            document.documentElement.setAttribute(offcanvasHtmlAttr, el.id)

        if (isOffcanvasActive && !isShown)
            document.documentElement.removeAttribute(offcanvasHtmlAttr)
    }

    const onShownOrHidden = () => {
        el._isShown = offcanvas._isShown

        setOffcanvasHtmlAttr()
    }

    el.addEventListener('hidden.bs.offcanvas', onShownOrHidden)

    el.addEventListener('shown.bs.offcanvas', onShownOrHidden)

    if (import.meta.hot) {
        import.meta.hot
            .on('vite:beforeUpdate', () => {
                el.removeEventListener('hidden.bs.offcanvas', onShownOrHidden)

                el.removeEventListener('shown.bs.offcanvas', onShownOrHidden)
            })

        import.meta.hot
            .on('vite:afterUpdate', () => {
                instance = Offcanvas.getInstance(el)

                if (el._isShown
                        && instance)
                    instance.show()

                setOffcanvasHtmlAttr(instance)
            })
    }

    console.log(el, offcanvas)

    console.groupEnd()

    return el
}
