export const selector = '.global-footer'

export default (
    el = null
) => {
    if (!el)
        return

    console.groupCollapsed('GlobalFooter')

    const collapseToggles = Array.from(
        el.querySelectorAll('[data-bs-toggle=collapse]'))

    collapseToggles
        .forEach((toggle) => {
            const collapsible = el
                .querySelector(toggle.getAttribute('data-bs-target'))

            if (!collapsible)
                return

            const onCollapseToggle = (e) => {
                const isShow = ((e || {}).type || '').match(/^show/)
                    || toggle.getAttribute('aria-expanded') === 'true'

                const label = isShow
                    ? toggle.getAttribute('data-aria-label-expanded')
                    : toggle.getAttribute('data-aria-label')

                if (label
                        && toggle.getAttribute('aria-label') !== label)
                    toggle.setAttribute('aria-label', label)
            }

            onCollapseToggle()

            collapsible
                .addEventListener('show.bs.collapse', onCollapseToggle)

            collapsible
                .addEventListener('hide.bs.collapse', onCollapseToggle)

            if (import.meta.hot)
                import.meta.hot
                    .on('vite:beforeUpdate', () => {
                        collapsible
                            .removeEventListener('show.bs.collapse',
                                onCollapseToggle)

                        collapsible
                            .removeEventListener('hide.bs.collapse',
                                onCollapseToggle)
                    })
        })

    console.groupEnd()

    return el
}
