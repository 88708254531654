export const selector = '.wallcalculator'

export default (
    el = null
) => {
    if (!el)
        return

    console.groupCollapsed('Wallcovering Calculator')

    const rollWidthEl = el
        .querySelector('[data-wallcalculator="rollwidth"]')
    const squareFtEl = el
        .querySelector('[data-wallcalculator="sqft"]')
    const wallHeightEl = el
        .querySelector('[data-wallcalculator="height"]')
    const wallWidthEl = el
        .querySelector('[data-wallcalculator="width"]')
    const resultEl = el
        .querySelector('[data-wallcalculator="result"]')
    const resultOutputEl = el
        .querySelector('[data-wallcalculator="result-output"]')

    const successCss = 'wallcalculator-result-success'

    let timer

    if (!rollWidthEl
        && !resultEl
        && !resultOutputEl
        && (!squareFtEl
            || (!wallHeightEl && !wallWidthEl)))
        return

    const toggleFields = () => {
        const isSqft = squareFtEl.value
        const isSides = wallHeightEl.value
            || wallWidthEl.value
        const allEmpty = !isSides && !isSqft

        if (allEmpty) {
            squareFtEl.removeAttribute('disabled')
            wallHeightEl.removeAttribute('disabled')
            wallWidthEl.removeAttribute('disabled')
            resultEl.classList.remove(successCss)
        }

        if (isSides && !allEmpty) {
            squareFtEl.setAttribute('disabled', 'disabled')
            squareFtEl.value = ''

            wallHeightEl.removeAttribute('disabled')
            wallWidthEl.removeAttribute('disabled')
        }

        if (isSqft && !allEmpty) {
            squareFtEl.removeAttribute('disabled')

            wallHeightEl.setAttribute('disabled', 'disabled')
            wallHeightEl.value = ''

            wallWidthEl.setAttribute('disabled', 'disabled')
            wallWidthEl.value = ''
        }
    }

    const calc = (e) => {
        clearTimeout(timer)

        const wait = (e.target.type === 'number')
            ? 300
            : 0

        timer = setTimeout(() => {
            toggleFields()

            const roll = +rollWidthEl.value

            const sqft = +squareFtEl.value
                || (+wallHeightEl.value * +wallWidthEl.value)

            if (roll && sqft) {
                resultEl.classList.add(successCss)
                resultOutputEl.textContent = Math.ceil(sqft / roll * 4)
            }
        }, wait)
    }

    if (rollWidthEl)
        rollWidthEl
            .addEventListener('change', calc)

    if (squareFtEl)
        squareFtEl
            .addEventListener('keyup', calc)

    if (wallHeightEl)
        wallHeightEl
            .addEventListener('keyup', calc)

    if (wallWidthEl)
        wallWidthEl
            .addEventListener('keyup', calc)

    if (import.meta.hot)
        import.meta.hot
            .on('vite:beforeUpdate', () => {
                if (rollWidthEl)
                    rollWidthEl
                        .removeEventListener('change', calc)

                if (squareFtEl)
                    squareFtEl
                        .removeEventListener('keyup', calc)

                if (wallHeightEl)
                    wallHeightEl
                        .removeEventListener('keyup', calc)

                if (wallWidthEl)
                    wallWidthEl
                        .removeEventListener('keyup', calc)
            })

    // Log instance
    console.log(el)

    console.groupEnd()

    return el
}
