//* ---------------------------------------
//  Vendors

import 'details-element-polyfill'
import 'focus-visible'
import 'focus-within-polyfill'
import 'objectFitPolyfill'
import 'whatwg-fetch'
import {
    Collapse,
    Dropdown,
    Modal,
    Offcanvas,
    Tab,
    Tooltip
} from 'bootstrap/dist/js/bootstrap.esm'

//* ---------------------------------------
// Utils

import { render } from './utils/components'
import { getCssVar, setCssVar } from './utils/css'
import {
    isTouchDevice,
    detectScrollDirection,
    getScrollbarWidth,
    scrollTo as animatedScrollTo
} from './utils/dom'

//* ---------------------------------------
//  Globally scoped utils

window.animatedScrollTo = animatedScrollTo

window.bootstrap = {
    Collapse,
    Dropdown,
    Modal,
    Offcanvas,
    Tab,
    Tooltip
}

//* ---------------------------------------
//  Feature Detection

const { userAgent } = window.navigator

let browserClassname = userAgent.match(/chrome/i)
    ? 'chrome'
    : ''

if (!browserClassname
        && userAgent.match(/safari/i))
    browserClassname = `safari${userAgent.match(/linux/i)
        ? ' linux'
        : ''}`

if (userAgent.match(/firefox/i))
    browserClassname = 'firefox'

if (browserClassname)
    document.body.className += ` ${browserClassname.trim()}`

// Touch Detection
document.body.classList
    .add(`${isTouchDevice()
        ? ''
        : 'no-'}touch`)

document.body.classList
    .add(`${getScrollbarWidth()
        ? ''
        : 'no-'}scrollbar-width`)

// Scrollbar width, Overlay is 0
setCssVar('--scrollbar-width',
    `${getScrollbarWidth() || 0}px`)

// Ensure var for CSS calc
if (!getCssVar('--wp-admin--admin-bar--height'))
    setCssVar('--wp-admin--admin-bar--height', '0px')

//* ---------------------------------------
// Components

const globalComponents = import.meta.globEager(
    '../components/(cookie-banner|global-footer|global-header|link|offcanvas|select|typography-chars|wallcalculator)/**/*.js')

const components = process.env.NODE_ENV === 'development'
    ? import.meta.globEager('../components/**/*.js')
    : globalComponents

//* ---------------------------------------
//  Render

detectScrollDirection()

let renderedComponents = render(components)

if (('MutationObserver' in window))
    (new window.MutationObserver((e) => {
        renderedComponents = render(components, '', false)
    })).observe(document.body, {
        childList: true,
        subtree: true
    })

//* ---------------------------------------
//  Top-level events

let onResizeDebounced

const onResize = (e) => {
    window.objectFitPolyfill()

    const resizeAttr = 'data-resizing'

    if (!document.documentElement.getAttribute(resizeAttr))
        document.documentElement.setAttribute(resizeAttr, true)

    if (onResizeDebounced)
        clearTimeout(onResizeDebounced)

    onResizeDebounced = setTimeout(() => {
        document.documentElement.removeAttribute(resizeAttr)
    }, 200)
}

onResize()

window.removeEventListener('resize', onResize)
window.addEventListener('resize', onResize)

const onDomContentLoaded = () => {
    let { scrollY } = window

    const isScrollLocked = () => document.body.style.overflow === 'hidden'

    const onScroll = () => !isScrollLocked()
        && (scrollY = window.scrollY)

    window.removeEventListener('scroll', onScroll)
    window.addEventListener('scroll', onScroll)

    const getElById = (id) => {
        let el

        try {
            el = document.getElementById(id.replace(/^#/, ''))
        }
        catch (err) {
            console.warn(err)
        }

        return el
    }

    const scrollToEl = (el) => {
        if (!el
                || isScrollLocked())
            return

        const isScrollable = document.body.clientHeight
            > document.documentElement.clientHeight

        if (!isScrollable)
            el.focus()

        if (isScrollable) {
            document.body.style.overflow = 'hidden'
            document.body.style.paddingRight = 'var(--scrollbar-width)'

            window.scrollTo(0, scrollY)

            document.body.style.overflow = ''
            document.body.style.paddingRight = ''

            animatedScrollTo(el)
        }
    }

    const onHashChange = (e) => {
        const { hash } = window.location

        if (!hash)
            return

        const el = getElById(hash)

        if (!el)
            return

        scrollToEl(el)

        window.location.hash = ''

        window.history
            .replaceState(window.history.state, null,
                window.location.href.replace(/#.*$/, ''))
    }

    onHashChange()

    window.removeEventListener('hashchange', onHashChange)
    window.addEventListener('hashchange', onHashChange)

    const onDocumentClick = (e) => {
        const { target } = e

        if (!target
                || target.nodeName !== 'A'
                || target.target)
            return

        const el = getElById((target.getAttribute('href') || '')
            .replace(/^[^#]+/, ''))

        if (!el)
            return

        e.preventDefault()

        scrollToEl(el)
    }

    document.removeEventListener('click', onDocumentClick)
    document.addEventListener('click', onDocumentClick)
}

document.removeEventListener('DOMContentLoaded', onDomContentLoaded)
document.addEventListener('DOMContentLoaded', onDomContentLoaded)

//* ---------------------------------------
//  Hot Module Replacement

if (import.meta.hot) {
    import.meta.hot.accept()

    import.meta.hot
        .dispose((data) => renderedComponents
            .filter(obj => typeof obj === 'object'
                && obj.destroy)
            .forEach(obj => obj.destroy()))
}
